// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import { container } from '../components/layout.module.css'
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"

// Step 2: Define your component
const Learn2 = () => {
  return (
    <div className={container}>
      <main>
        <Layout>

          <StaticImage src="../../static/2-learn.jpg" alt="Get Into Investing!" layout="constrained" />

          <table align="center" width="90%">
            <tr>
              <td>
              <div><br />
                <p>
                  The most important lesson that <i>Get Into Investing!</i> teaches is risk! Without this subject, new investors are thrown into sorting a lot of topics out by themselves. 
                </p>
                <p>
                <i>Get Into Investing!</i> teaches readers how to handle this difficult subject with ease. Using a few simple rules for navigating choices in the industry, you are armed with the information you need to decide quickly if a certain security is worth your time. 
                </p>
                <p>
                  Without the subject of risk, you are gambling with your investment capital without any certain way of knowing if you need to dump the investment or not. <i>Get Into Investing!</i> shows readers how to quickly analyze if one investment is better than another, while keeping risk at bay!
                </p>
                <p>
                  Investing in the stock market shouldn't have strings attached. It should be kept in mind this type of investment is for long "and short" gains. Risk helps keep the direction of gains pointed in the positive direction. 
                </p>
                <p>
                  What are you waiting for? It's time to <i>Get Into Investing!</i>
                </p>
              </div>
              <div align="center">
                    <p>
                      <>  <Link className="btn btn-outline-secondary" to="/learn3"> Learn More </Link> </>
                      <>  <Link className="btn btn-outline-secondary" to="/buy"> Buy Literature </Link> </>
                      <>  <Link className="btn btn-outline-secondary" to="/social"> Social Media </Link> </>
                    </p>
                  </div>
              </td>  
            </tr>
          </table>
        </Layout>
      </main>
    </div>
  )
}

// Step 3: Export your component
export default Learn2